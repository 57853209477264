import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import LyricsLanding from '../components/LyricsLanding'
import Cat from '../assets/images/kitty.jpg'

const Lyrics = props => (
  <Layout>
    <Helmet>
      <title>Melancholia - Lyrics</title>
      <meta name="description" content="Lyrics" />
    </Helmet>

    <LyricsLanding />
    <div id="main" className="alt">
      <section id="lyrics">
        <div className="inner">
          <div className="grid-wrapper">
            <div className="col-6">
              <h3>Hourglass</h3>
              <p>
                The Moments are fleeting
                <br />
                Our life is deceiving, deceiving
                <br />
              </p>

              <p>
                <strong>[Chorus]</strong>
                <br />
                The moments are fleeting
                <br />
                I look around and watch them pass
                <br />
                The Days are completing
                <br />
                I'm locked in time with no way out
                <br />
                Our life is deceiving
                <br />
                Beyond a shadow of a doubt
                <br />
                Our time is depleting
                <br />
                all 700 thousand hours
                <br />
                <strong>[End Chorus]</strong>
              </p>

              <p>
                Never time to rest
                <br />
                I worry myself into debt
                <br />
                I face the consequence
                <br />
                But it's never enough
                <br />
                Living life in fear
                <br />
                Im waiting for this fog to clear
                <br />
                anywhere but here
                <br />
                But its never enough
                <br />
                Its never enough
                <br />
                No its never enough
                <br />
              </p>
            </div>
            <div className="col-6">
              <h3>Melancholia</h3>
              <p>
                I'm feeling lost and never found
                <br />
                Trying my best to hold my breath so I don't drown
                <br />
                I've let time decide my fate
                <br />
                Now im scared and unprepared but its too late
                <br />
                Yeah it's too late
              </p>

              <h3>When I Wake Up</h3>
              <p>
                Buried Alive <br />
                Sick of the lies
                <br />
                Lost with no time
              </p>

              <img src={Cat} />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Lyrics
