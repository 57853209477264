import React from 'react'

const LyricsLanding = props => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>Lyrics</h1>
      </header>
      <div className="content">
        <p>Written by Andy Miller and Landon Jones</p>
      </div>
    </div>
  </section>
)

export default LyricsLanding
